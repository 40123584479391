"use client";
import { Constants } from "@openqlabs/utils";
import { useSession } from "next-auth/react";
import { useEffect, useCallback, useState } from "react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/server/trpc/react";

const ActivityTracker = () => {
  const { data: session } = useSession();
  const inactivityLimit = 15 * 60 * 1000; // 15 minutes in milliseconds
  const handleActivePeriod =
    api.activityPeriod.handleUserActivityMessage.useMutation();

  const { teamAccountUser } = useTeamAccountUser();
  const [lastActivityTime, setLastActivityTime] = useState(0);
  const { activeTeamAccount } = useTeamAccount();
  //  notify backend of user activity
  const handleActivity = useCallback(() => {
    const currentTime = new Date().getTime();
    if (
      teamAccountUser &&
      currentTime - lastActivityTime > Constants.ONE_MINUTE
    ) {
      // 1 minute in milliseconds
      handleActivePeriod.mutate({
        userId: teamAccountUser.id,
        currentTime: currentTime,
        teamAccountId: activeTeamAccount.id,
      });
      setLastActivityTime(currentTime);
    }
  }, [teamAccountUser, handleActivePeriod, lastActivityTime]);

  // set up event listeners for mousemove, keydown, and scroll events
  useEffect(() => {
    const activityEvents = ["mousemove", "keydown", "scroll"];

    activityEvents.forEach((event) =>
      window.addEventListener(event, handleActivity)
    );
    // Clean up
    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
    };
  }, [session, handleActivity, inactivityLimit]);

  return null;
};

export default ActivityTracker;
